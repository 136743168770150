import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

type RowProps = {
  label: string;
  value: string;
};

export function Row({ label, value = '' }: RowProps) {
  return (
    <Stack direction="row" sx={{ typography: 'caption' }}>
      <Box component="span" sx={{ width: 200, color: 'text.secondary', mr: 2 }}>
        <Typography>{label}</Typography>
      </Box>

      <Typography>{value}</Typography>
    </Stack>
  );
}
