// routes
import { PATH_APPLICATION } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';
import { AccountPermission } from '../../../data/AccountData';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  // GUARD
  // ----------------------------------------------------------------------
  {
    subheader: 'Guard Users',
    items: [
      {
        title: 'licenses',
        path: PATH_APPLICATION.admin.guard.license,
        icon: ICONS.dashboard,
        roles: [AccountPermission.SUDO, AccountPermission.ADMIN],
      },
      {
        title: 'sessions',
        path: PATH_APPLICATION.admin.guard.session,
        icon: ICONS.dashboard,
        roles: [AccountPermission.SUDO, AccountPermission.ADMIN],
      },
      {
        title: 'subscriptions',
        path: PATH_APPLICATION.admin.guard.subscription,
        icon: ICONS.dashboard,
        roles: [AccountPermission.SUDO, AccountPermission.ADMIN],
      },
      {
        title: 'screenshots',
        path: PATH_APPLICATION.admin.guard.screenshot,
        icon: ICONS.dashboard,
        roles: [AccountPermission.SUDO, AccountPermission.ADMIN],
      },
      // {title: 'logs', path: PATH_PAGE.comingSoon, icon: ICONS.dashboard},
    ],
  },

  // GUARD CONFIGURATION
  // ----------------------------------------------------------------------
  {
    subheader: 'Guard Configuration',
    items: [
      {
        title: 'versions',
        path: PATH_APPLICATION.admin.guard.versions,
        icon: ICONS.dashboard,
        roles: [AccountPermission.SUDO],
      },
      {
        title: 'files',
        path: PATH_APPLICATION.admin.guard.files,
        icon: ICONS.dashboard,
        roles: [AccountPermission.SUDO, AccountPermission.ADMIN],
      },
      {
        title: 'items',
        path: PATH_APPLICATION.admin.guard.item,
        icon: ICONS.dashboard,
        roles: [AccountPermission.SUDO, AccountPermission.ADMIN],
      },
      {
        title: 'blacklist',
        path: PATH_APPLICATION.admin.guard.program,
        icon: ICONS.dashboard,
        roles: [AccountPermission.SUDO],
      },
      {
        title: 'configs',
        path: PATH_APPLICATION.admin.guard.config,
        icon: ICONS.dashboard,
        roles: [AccountPermission.SUDO],
      },
    ],
  },

  // PUG CONFIGURATION
  // ----------------------------------------------------------------------
  {
    subheader: 'Pug Configuration',
    roles: [AccountPermission.SUDO],
    items: [
      {
        title: 'servers',
        path: PATH_APPLICATION.admin.pug.server,
        icon: ICONS.dashboard,
      },
      {
        title: 'sessions',
        path: PATH_APPLICATION.admin.pug.session,
        icon: ICONS.dashboard,
      },
      {
        title: 'configs',
        path: PATH_APPLICATION.admin.pug.config,
        icon: ICONS.dashboard,
      },
      {
        title: 'channels',
        path: PATH_APPLICATION.admin.pug.channel,
        icon: ICONS.dashboard,
      },
    ],
  },
];

export default navConfig;
