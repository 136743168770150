// @mui
import { alpha, styled } from '@mui/material/styles';
import { bgGradient } from '../../utils/cssStyles';
// utils

// ----------------------------------------------------------------------

export const StyledRoot = styled('main')(() => ({
  height: '100%',
  display: 'flex',
  position: 'relative',
}));

export const StyledSectionBg = styled('div')(({ theme }) => ({
  ...bgGradient({
    color: alpha(theme.palette.background.default, theme.palette.mode === 'light' ? 0.7 : 0.74),
    imgUrl: '/assets/background/sof2.jpg',
  }),
  top: 0,
  left: 0,
  zIndex: -1,
  width: '100vw',
  height: '100vh',
  position: 'fixed',
}));
