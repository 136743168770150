import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { timer } from 'rxjs';
import { useAuthContext } from '../../../auth/useAuthContext';
import axios from '../../../utils/axios';
import { minimumDelay } from '../../../utils/MinimalDelay';
import { PugChannelData, PugChannelUpdateData } from '../../../data/PugChannelData';

export interface PugChannelContextType {
  channels: PugChannelData[] | undefined;

  updateChannel(id: string, item: Omit<PugChannelUpdateData, 'id' | 'name'>): void;

  getById(id: string): PugChannelData | undefined;

  refresh(): Promise<void>;
}

export const PugChannelContext = React.createContext<PugChannelContextType>(undefined as never);

export const PugChannelProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();

  const [channels, setChannels] = useState<PugChannelData[]>();

  const updatedRef = useRef<number>();

  const load = useCallback(() => {
    const updated = updatedRef.current;
    return axios.get<PugChannelData[]>('/pug/v1/channels').then((res) => {
      if (updatedRef.current !== updated) {
        // Discard stale data
        return;
      }

      setChannels((prevFiles) => {
        if (JSON.stringify(prevFiles) !== JSON.stringify(res.data)) {
          return res.data.sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }

            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }

            return 0;
          });
        }
        return prevFiles;
      });
    });
  }, []);

  const fastRefresh = useMemo(() => !(Array.isArray(channels) && channels.length), [channels]);

  useEffect(() => {
    if (!isAuthenticated) {
      setChannels(undefined);
      return undefined;
    }
    const interval = 3_000;
    const refreshTick = 30_000 / interval;
    const subscription = timer(0, interval).subscribe((tick) => {
      if (fastRefresh || (tick > 0 && tick % refreshTick === 0)) {
        return load();
      }
      return undefined;
    });
    return () => subscription.unsubscribe();
  }, [load, fastRefresh, isAuthenticated]);

  const updateChannel: PugChannelContextType['updateChannel'] = useCallback(
    async (id: string, item: PugChannelUpdateData) => {
      await minimumDelay(axios.put(`/pug/v1/channels/${id}`, item));
      await load();
    },
    [load]
  );

  const getById = useCallback<PugChannelContextType['getById']>(
    (id) => {
      if (channels) {
        return channels.find((channel) => channel.id === id);
      }
      return undefined;
    },
    [channels]
  );

  const value = useMemo<PugChannelContextType>(
    () => ({
      channels,
      updateChannel,
      getById,
      refresh: load,
    }),
    [channels, getById, load, updateChannel]
  );

  return <PugChannelContext.Provider value={value}>{children}</PugChannelContext.Provider>;
};

export const usePugChannelContext = () => {
  const context = useContext(PugChannelContext);

  if (!context)
    throw new Error('usePugChannelContext context must be use inside PugChannelProvider');

  return context;
};
