import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { timer } from 'rxjs';
import { useAuthContext } from '../../../auth/useAuthContext';
import axios from '../../../utils/axios';
import { SessionData } from '../../../data/SessionData';

export interface GuardSessionContextType {
  sessions: SessionData[] | undefined;

  getById(id: string): SessionData | undefined;

  refresh(): Promise<void>;
}

export const GuardSessionContext = React.createContext<GuardSessionContextType>(undefined as never);

export const GuardSessionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();

  const [sessions, setSessions] = useState<SessionData[]>();

  const updatedRef = useRef<number>();

  const load = useCallback(() => {
    const updated = updatedRef.current;
    return axios.get<SessionData[]>('/guard/v1/sessions').then((res) => {
      if (updatedRef.current !== updated) {
        // Discard stale data
        return;
      }

      setSessions((prevFiles) => {
        if (JSON.stringify(prevFiles) !== JSON.stringify(res.data)) {
          return res.data;
        }
        return prevFiles;
      });
    });
  }, []);

  const fastRefresh = useMemo(() => !(Array.isArray(sessions) && sessions.length), [sessions]);

  useEffect(() => {
    if (!isAuthenticated) {
      setSessions(undefined);
      return undefined;
    }

    const interval = 3_000;
    const refreshTick = 30_000 / interval;
    const subscription = timer(0, interval).subscribe((tick) => {
      if (fastRefresh || (tick > 0 && tick % refreshTick === 0)) {
        return load();
      }

      return undefined;
    });
    return () => subscription.unsubscribe();
  }, [load, fastRefresh, isAuthenticated]);

  const getById = useCallback<GuardSessionContextType['getById']>(
    (id) => {
      if (sessions) {
        return sessions.find((session) => session.id === id);
      }
      return undefined;
    },
    [sessions]
  );

  const value = useMemo<GuardSessionContextType>(
    () => ({
      sessions,
      getById,
      refresh: load,
    }),
    [sessions, getById, load]
  );

  return <GuardSessionContext.Provider value={value}>{children}</GuardSessionContext.Provider>;
};

export const useGuardSessionContext = () => {
  const context = useContext(GuardSessionContext);

  if (!context)
    throw new Error('useGuardSessionContext context must be use inside GuardSessionProvider');

  return context;
};
