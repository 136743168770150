import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Stack } from '@mui/material';
// hooks
import useActiveLink from '../../../../hooks/useActiveLink';
//
import { NavItemProps } from '../types';
import { NavItem, NavItemDashboard } from './NavItem';
import { StyledPopover } from '../../../../components/nav-section/horizontal/styles';

// ----------------------------------------------------------------------

type NavListProps = {
  item: NavItemProps;
  isOffset: boolean;
};

export default function NavList({ item, isOffset }: NavListProps) {
  const navRef = useRef(null);

  const { pathname } = useLocation();

  const [openMenu, setOpenMenu] = useState(false);

  const { path, children } = item;

  const { active, isExternalLink } = useActiveLink(path, true);

  useEffect(() => {
    if (openMenu) {
      handleCloseMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpenMenu = () => {
    if (children) {
      setOpenMenu(true);
    }
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };

  return (
    <>
      <NavItem
        ref={navRef}
        item={item}
        isOffset={isOffset}
        active={active}
        open={openMenu}
        isExternalLink={isExternalLink}
        onMouseEnter={handleOpenMenu}
        onMouseLeave={handleCloseMenu}
        sx={{ fontSize: '20px', height: 'fit-content' }}
      />

      {/* !!children && openMenu && (
                <Portal>
                    <Fade in={openMenu}>
                        <StyledMenu onMouseEnter={handleOpenMenu} onMouseLeave={handleCloseMenu}>
                            {children.map((list) => (
                                <NavSubList
                                    key={list.subheader}
                                    subheader={list.subheader}
                                    items={list.items}
                                    isDashboard={list.subheader === 'Dashboard'}
                                    onClose={handleCloseMenu}
                                />
                            ))}
                        </StyledMenu>
                    </Fade>
                </Portal>
            ) */}

      {!!children && (
        <StyledPopover
          open={openMenu}
          anchorEl={navRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          PaperProps={{
            onMouseEnter: handleOpenMenu,
            onMouseLeave: handleCloseMenu,
          }}
        >
          {children.map((list) => (
            <NavSubList
              key={list.subheader}
              subheader={list.subheader}
              items={list.items}
              isDashboard={list.subheader === 'Dashboard'}
              onClose={handleCloseMenu}
            />
          ))}
        </StyledPopover>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

type NavSubListProps = {
  isDashboard: boolean;
  subheader: string;
  items: NavItemProps[];
  onClose: VoidFunction;
};

function NavSubList({ items, isDashboard, subheader, onClose }: NavSubListProps) {
  const { pathname } = useLocation();

  const isActive = (path: string) => pathname === path;

  return (
    <Stack spacing={2.5} gridColumn={isDashboard ? 'span 6' : 'span 2'} alignItems="flex-start">
      {/* <StyledSubheader disableSticky>{subheader}</StyledSubheader> */}

      {items.map((item) =>
        isDashboard ? (
          <NavItemDashboard key={item.title} item={item} onClick={onClose} />
        ) : (
          <NavItem
            subItem
            key={item.title}
            item={item}
            active={isActive(item.path)}
            onClick={onClose}
            sx={{ paddingLeft: 1 }}
          />
        )
      )}
    </Stack>
  );
}
