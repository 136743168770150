import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { timer } from 'rxjs';
import { useAuthContext } from '../../../auth/useAuthContext';
import axios from '../../../utils/axios';
import { GameServerSessionData } from '../../../data/GameServerSessionData';

export interface PugServerSessionContextType {
  sessions: GameServerSessionData[] | undefined;

  getById(id: string): GameServerSessionData | undefined;

  refresh(): Promise<void>;
}

export const PugServerSessionContext = React.createContext<PugServerSessionContextType>(
  undefined as never
);

export const PugServerSessionProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();

  const [sessions, setSessions] = useState<GameServerSessionData[]>();

  const updatedRef = useRef<number>();

  const load = useCallback(() => {
    const updated = updatedRef.current;
    return axios.get<GameServerSessionData[]>('/pug/v1/sessions').then((res) => {
      if (updatedRef.current !== updated) {
        // Discard stale data
        return;
      }

      setSessions((prevFiles) => {
        if (JSON.stringify(prevFiles) !== JSON.stringify(res.data)) {
          return res.data;
        }
        return prevFiles;
      });
    });
  }, []);

  const fastRefresh = useMemo(() => !(Array.isArray(sessions) && sessions.length), [sessions]);

  useEffect(() => {
    if (!isAuthenticated) {
      setSessions(undefined);
      return undefined;
    }
    const interval = 3_000;
    const refreshTick = 30_000 / interval;
    const subscription = timer(0, interval).subscribe((tick) => {
      if (fastRefresh || (tick > 0 && tick % refreshTick === 0)) {
        return load();
      }
      return undefined;
    });
    return () => subscription.unsubscribe();
  }, [load, fastRefresh, isAuthenticated]);

  const getById = useCallback<PugServerSessionContextType['getById']>(
    (id) => {
      if (sessions) {
        return sessions.find((session) => session.id === id);
      }
      return undefined;
    },
    [sessions]
  );

  const value = useMemo<PugServerSessionContextType>(
    () => ({
      sessions,
      getById,
      refresh: load,
    }),
    [sessions, getById, load]
  );

  return (
    <PugServerSessionContext.Provider value={value}>{children}</PugServerSessionContext.Provider>
  );
};

export const usePugServerSessionContext = () => {
  const context = useContext(PugServerSessionContext);

  if (!context)
    throw new Error(
      'usePugServerSessionContext context must be use inside PugServerSessionProvider'
    );

  return context;
};
