import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { timer } from 'rxjs';

import { useAuthContext } from '../../../auth/useAuthContext';
import axios from '../../../utils/axios';
import { BannedProgramData } from '../../../data/BannedProgramData';
import { minimumDelay } from '../../../utils/MinimalDelay';

export interface GuardProgramContextType {
  bannedPrograms: BannedProgramData[] | undefined;

  createBannedProgram(program: Omit<BannedProgramData, 'id'>): void;

  deleteBannedProgram(program: BannedProgramData): void;

  getById(id: string): BannedProgramData | undefined;

  refresh(): Promise<void>;
}

export const GuardProgramContext = React.createContext<GuardProgramContextType>(undefined as never);

export const GuardProgramProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();

  const [bannedPrograms, setBannedPrograms] = useState<BannedProgramData[]>();

  const updatedRef = useRef<number>();

  const load = useCallback(() => {
    const updated = updatedRef.current;
    return axios.get<BannedProgramData[]>('/guard/v1/programs/banned').then((res) => {
      if (updatedRef.current !== updated) {
        // Discard stale data
        return;
      }

      setBannedPrograms((prevFiles) => {
        if (JSON.stringify(prevFiles) !== JSON.stringify(res.data)) {
          return res.data.sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }

            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }

            return 0;
          });
        }
        return prevFiles;
      });
    });
  }, []);

  const fastRefresh = useMemo(
    () => !(Array.isArray(bannedPrograms) && bannedPrograms.length),
    [bannedPrograms]
  );

  useEffect(() => {
    if (!isAuthenticated) {
      setBannedPrograms(undefined);
      return undefined;
    }

    const interval = 3_000;
    const refreshTick = 30_000 / interval;
    const subscription = timer(0, interval).subscribe((tick) => {
      if (fastRefresh || (tick > 0 && tick % refreshTick === 0)) {
        return load();
      }
      return undefined;
    });

    return () => subscription.unsubscribe();
  }, [load, fastRefresh, isAuthenticated]);

  const createBannedProgram: GuardProgramContextType['createBannedProgram'] = useCallback(
    async (program: BannedProgramData) => {
      await minimumDelay(axios.post(`/guard/v1/programs/banned`, program));
      await load();
    },
    [load]
  );

  const deleteBannedProgram: GuardProgramContextType['deleteBannedProgram'] = useCallback(
    async (program: BannedProgramData) => {
      await minimumDelay(axios.delete(`/guard/v1/programs/banned/${program.id}`));
      await load();
    },
    [load]
  );

  const getById = useCallback<GuardProgramContextType['getById']>(
    (id) => {
      if (bannedPrograms) {
        return bannedPrograms.find((bannedProgram) => bannedProgram.id === id);
      }
      return undefined;
    },
    [bannedPrograms]
  );

  const value = useMemo<GuardProgramContextType>(
    () => ({
      bannedPrograms,
      createBannedProgram,
      deleteBannedProgram,
      getById,
      refresh: load,
    }),
    [bannedPrograms, createBannedProgram, deleteBannedProgram, getById, load]
  );

  return <GuardProgramContext.Provider value={value}>{children}</GuardProgramContext.Provider>;
};

export const useGuardProgramContext = () => {
  const context = useContext(GuardProgramContext);

  if (!context)
    throw new Error('useGuardProgramContext context must be use inside GuardProgramProvider');

  return context;
};
