import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { timer } from 'rxjs';
import { ItemData } from '../../../data/ItemData';
import { useAuthContext } from '../../../auth/useAuthContext';
import axios from '../../../utils/axios';
import { minimumDelay } from '../../../utils/MinimalDelay';

export interface GuardItemContextType {
  items: ItemData[] | undefined;

  createItem(item: Omit<ItemData, 'id' | 'createdDate'>): void;

  getById(id: string): ItemData | undefined;

  deleteItem(item: ItemData): void;

  refresh(): Promise<void>;
}

export const GuardItemContext = React.createContext<GuardItemContextType>(undefined as never);

export const GuardItemProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();

  const [items, setItems] = useState<ItemData[]>();

  const updatedRef = useRef<number>();

  const load = useCallback(() => {
    const updated = updatedRef.current;
    return axios.get<ItemData[]>('/guard/v1/items').then((res) => {
      if (updatedRef.current !== updated) {
        // Discard stale data
        return;
      }

      setItems((prevFiles) => {
        if (JSON.stringify(prevFiles) !== JSON.stringify(res.data)) {
          return res.data.sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }

            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }

            return 0;
          });
        }
        return prevFiles;
      });
    });
  }, []);

  const fastRefresh = useMemo(() => !(Array.isArray(items) && items.length), [items]);

  useEffect(() => {
    if (!isAuthenticated) {
      setItems(undefined);
      return undefined;
    }
    const interval = 3_000;
    const refreshTick = 30_000 / interval;
    const subscription = timer(0, interval).subscribe((tick) => {
      if (fastRefresh || (tick > 0 && tick % refreshTick === 0)) {
        return load();
      }
      return undefined;
    });
    return () => subscription.unsubscribe();
  }, [load, fastRefresh, isAuthenticated]);

  const createItem: GuardItemContextType['createItem'] = useCallback(
    async (item: ItemData) => {
      await minimumDelay(axios.post(`/guard/v1/items`, item));
      await load();
    },
    [load]
  );

  const deleteItem: GuardItemContextType['deleteItem'] = useCallback(
    async (item: ItemData) => {
      await minimumDelay(axios.delete(`/guard/v1/items/${item.id}`));
      await load();
    },
    [load]
  );

  const getById = useCallback<GuardItemContextType['getById']>(
    (id) => {
      if (items) {
        return items.find((item) => item.id === id);
      }
      return undefined;
    },
    [items]
  );

  const value = useMemo<GuardItemContextType>(
    () => ({
      items,
      createItem,
      deleteItem,
      getById,
      refresh: load,
    }),
    [items, createItem, deleteItem, getById, load]
  );

  return <GuardItemContext.Provider value={value}>{children}</GuardItemContext.Provider>;
};

export const useGuardItemContext = () => {
  const context = useContext(GuardItemContext);

  if (!context) throw new Error('useGuardItemContext context must be use inside GuardItemProvider');

  return context;
};
