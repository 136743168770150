import { m } from 'framer-motion';
// @mui
import { Container, Typography } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { ForbiddenIllustration } from '../assets/illustrations';
//
import { useAuthContext } from './useAuthContext';
import { AccountPermission } from '../data/AccountData';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  useOriginalRole?: boolean;
  hasContent?: boolean;
  roles?: string[];
  children: React.ReactNode;
};

export default function RoleBasedGuard({
  useOriginalRole,
  hasContent,
  roles,
  children,
}: RoleBasedGuardProp) {
  // Logic here to get current user role
  const { user, hasRole } = useAuthContext();

  // const currentRole = 'user';
  const currentRole = user?.permission as string;

  if (
    typeof roles !== 'undefined' &&
    (useOriginalRole
      ? !roles.includes(currentRole)
      : !roles.find((it) => hasRole(it as AccountPermission)))
  ) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            You do not have permission to access this page
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  }

  return <> {children} </>;
}
