import { AxiosError, AxiosRequestConfig } from 'axios';
import { HttpStatus } from './HttpStatus';

export enum ErrorCode {
  REQUIRED = 'REQUIRED',
  CONFLICT = 'CONFLICT',
  VALIDATION = 'VALIDATION',
  SCHEDULE_NEVER_PLAYER = 'SCHEDULE_NEVER_PLAYED',
  EXTERNAL_MAIL_BLOCKED = 'EXTERNAL_MAIL_BLOCKED',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  /**
   * User has reached the uploaded document limit, and must delete documents to make place for new documents.
   */
  DOCUMENT_LIMIT = 'DOCUMENT_LIMIT',

  DOCUMENT_UNSUPPORTED_FILE_EXTENSION = 'DOCUMENT_UNSUPPORTED_FILE_EXTENSION',
  QR_EXPIRED = 'QR_EXPIRED',
  PASSWORD_COMPLEXITY = 'PASSWORD_COMPLEXITY',
  EMAIL_VERIFICATION_COOLDOWN = 'EMAIL_VERIFICATION_COOLDOWN',
  EMAIL_ALREADY_VERIFIED = 'EMAIL_ALREADY_VERIFIED',
  EMAIL_VERIFICATION_UNKNOWN = 'EMAIL_VERIFICATION_UNKNOWN',
  EMAIL_VERIFICATION_EXPIRED = 'EMAIL_VERIFICATION_EXPIRED',
}

interface ErrorDetail {
  code: ErrorCode;
  field?: string;
  detail?: string;
}

interface TechnicalErrorDetail {
  label: string;
  detail: any;
}

export class EbError extends Error {
  errors?: ErrorDetail[];

  details?: TechnicalErrorDetail[];

  data?: any;

  /**
   *
   * @param status HTTP status
   * @param error Detailed util field
   * @param message Optional util message
   */
  constructor(public status: number, public error?: string, message?: string) {
    super(message || `An error occurred (status=${status})`);
  }

  static fromAxios(e: any): EbError {
    if (e instanceof EbError) {
      // Backwards compatibility. We introduced request interceptor that performs fromAxios on util responses
      // Also when we catch a util we are not sure if it is an ApiError or e.g. NPE, so we always have to perform fromAxios() call
      return e;
    }
    const details: TechnicalErrorDetail[] = [];
    if (e instanceof Error) {
      details.push({
        label: 'Error message',
        detail: e.message,
      });
      const axiosError = e as AxiosError<EbError>;
      if (axiosError.isAxiosError) {
        details.push({
          label: 'Type',
          detail: 'Axios (HTTP request)',
        });
        const { config } = axiosError;
        if (config) {
          const labelMapping: {
            [key in keyof AxiosRequestConfig]: any;
          } = {
            baseURL: 'Base URL',
            url: 'Path',
            method: 'HTTP method',
            timeout: 'Timeout (ms)',
            headers: undefined,
          };
          Object.entries(labelMapping).forEach(([key, label]) => {
            details.push({
              label,
              detail: config[key as keyof AxiosRequestConfig],
            });
          });
        }

        const { response } = axiosError;

        details.push({
          label: 'Response code',
          detail: response ? response.status : 'No response',
        });

        details.push({
          label: 'Response',
          detail: response ? response.data : 'No response',
        });
        if (response && response.status) {
          const apiError = new EbError(response.status);
          apiError.details = details;
          apiError.data = axiosError.response?.data;

          const errors = axiosError.response?.data?.errors;
          if (Array.isArray(errors)) {
            apiError.errors = errors;
          }

          return apiError;
        }
        if (axiosError.message.toLowerCase().includes('timeout')) {
          const apiError = new EbError(HttpStatus.TIMEOUT);
          apiError.details = details;
          apiError.data = axiosError.response?.data;
          return apiError;
        }
        if (axiosError.message.toLowerCase().includes('network')) {
          // Most likely a network util
          const apiError = new EbError(HttpStatus.ERROR_UNAVAILABLE, 'NETWORK');
          apiError.details = details;
          apiError.data = axiosError.response?.data;
          return apiError;
        }
      }
    }
    const apiError = new EbError(HttpStatus.ERROR, 'UNKNOWN');
    apiError.details = e.message;
    return apiError;
  }

  getErrors(): ErrorDetail[] {
    if (Array.isArray(this.errors)) {
      return this.errors;
    }
    return [];
  }

  getFieldErrors(field: string): ErrorDetail[] {
    return this.getErrors().filter((err) => err.field === field);
  }

  hasFieldErrorCode(field: string, code: ErrorCode): boolean {
    return this.getFieldErrors(field).some((error) => error.code === code);
  }

  hasErrorCode(code: ErrorCode): boolean {
    return this.getErrors().some((error) => error.code === code);
  }
}
