import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { timer } from 'rxjs';
import { useSnackbar } from 'notistack';
import { useAuthContext } from 'src/auth/useAuthContext';
import { GuardFileData } from '../../../data/GuardFileData';
import { GuardFileUploadingData } from '../../../data/GuardFileUploadingData';
import axios from '../../../utils/axios';
import { minimumDelay } from '../../../utils/MinimalDelay';
import { fileData } from '../../../components/file-thumbnail';

export interface GuardFileContextType {
  files: GuardFileData[] | undefined;
  filesUploading: GuardFileUploadingData[];

  deleting: string[];
  uploading: boolean;

  getById(id: string): GuardFileData | undefined;

  deleteFile(file: GuardFileData): void;

  handleFileUpload(eFiles: File[]): void;

  refresh(): Promise<void>;

  updateFile(id: string, data: Partial<GuardFileData>): void;
}

export const GuardFileContext = React.createContext<GuardFileContextType>(undefined as never);

export const GuardFileProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState<GuardFileData[]>();
  const [deleting, setDeleting] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false);
  const [filesUploading, setFilesUploading] = useState<GuardFileUploadingData[]>([]);

  const updatedRef = useRef<number>();

  const updateFile: GuardFileContextType['updateFile'] = useCallback((id, data) => {
    setFiles((prevFiles) => {
      updatedRef.current = Date.now();
      if (Array.isArray(prevFiles)) {
        const index = prevFiles.findIndex((prevFile) => prevFile.id === id);
        if (index >= 0) {
          const newFiles = [...prevFiles];
          newFiles[index] = {
            ...prevFiles[index],
            ...data,
          };
          return newFiles;
        }
      }
      return prevFiles;
    });
  }, []);

  const load = useCallback(() => {
    const updated = updatedRef.current;
    return axios.get<GuardFileData[]>('/guard/v1/files').then((res) => {
      if (updatedRef.current !== updated) {
        // Discard stale data
        return;
      }

      setFiles((prevFiles) => {
        if (JSON.stringify(prevFiles) !== JSON.stringify(res.data)) {
          return res.data;
        }
        return prevFiles;
      });
    });
  }, []);

  const fastRefresh = useMemo(() => !(Array.isArray(files) && files.length), [files]);

  useEffect(() => {
    if (isAuthenticated) {
      const interval = 3_000;
      const refreshTick = 30_000 / interval;
      const subscription = timer(0, interval).subscribe((tick) => {
        if (fastRefresh || (tick > 0 && tick % refreshTick === 0)) {
          return load();
        }

        return undefined;
      });
      return () => subscription.unsubscribe();
    }

    setFiles(undefined);

    return undefined;
  }, [load, fastRefresh, isAuthenticated]);

  const deleteFile: GuardFileContextType['deleteFile'] = useCallback(
    async (file: GuardFileData) => {
      setDeleting((prevDeleting) => [file.id, ...prevDeleting]);
      await minimumDelay(axios.delete(`/guard/v1/files/${file.id}`));
      setFiles((prevFiles) => {
        if (Array.isArray(prevFiles)) {
          return prevFiles.filter((prevFile) => prevFile.id !== file.id);
        }
        return prevFiles;
      });
      setDeleting((prevDeleting) => prevDeleting.filter((id) => id !== file.id));
    },
    []
  );

  /**
   * @param progress 0-100 for progress, when negative the file is removed
   */
  const setUploadProgress = useCallback((id: string, progress: number) => {
    setFilesUploading((prev) => {
      const _filesUploading = [...prev];
      const index = _filesUploading.findIndex((file) => file.id === id);
      if (index >= 0) {
        if (progress < 0) {
          _filesUploading.splice(index, 1);
        } else {
          _filesUploading[index] = {
            ..._filesUploading[index],
            progress,
          };
        }
      }
      return _filesUploading;
    });
  }, []);

  const handleFileUpload = useCallback(
    (eFiles: File[]) => {
      if (eFiles && eFiles.length) {
        if (eFiles.length > 10) {
          enqueueSnackbar('Selecteer maximaal 10 bestanden per keer!', { variant: 'error' });

          // e.currentTarget.value = '';
          return;
        }
        setUploading(true);
        // @ts-ignore
        const promises = [...eFiles].map((file) => {
          const { folder, mandatory } = fileData(file);

          const tempId = crypto.randomUUID();
          const formData = new FormData();
          console.log(file.name);
          formData.append('file', file, file.name);
          setFilesUploading((prev) => [
            ...prev,
            {
              id: tempId,
              name: file.name,
              progress: 0,
            },
          ]);
          return axios
            .post<GuardFileData[]>(`/guard/v1/files`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              params: {
                folder,
                mandatory,
              },
              timeout: 300_000,
            })
            .then((res) => {
              setFiles((prevFiles) => {
                if (Array.isArray(prevFiles)) {
                  const newFiles = [...prevFiles];
                  const newFile = res.data[0];
                  if (newFiles.every((pf) => newFile.id !== pf.id)) {
                    // Only insert the newFile if it isn't already listed (this can occur due to polling)
                    newFiles.push(newFile);
                  }
                  newFiles.sort(
                    (a, b) => new Date(b.uploaded).getTime() - new Date(a.uploaded).getTime()
                  );
                  return newFiles;
                }
                return res.data;
              });
            })
            .catch((err) => {
              enqueueSnackbar('Er ging iets fout bij het uploaden', { variant: 'error' });
            })
            .finally(() => {
              setUploadProgress(tempId, -1);
            });
        });
        // e.currentTarget.value = '';
        Promise.all(promises).finally(() => {
          setUploading(false);
        });
      }
    },
    [enqueueSnackbar, setUploadProgress]
  );

  const getById = useCallback<GuardFileContextType['getById']>(
    (id) => {
      if (files) {
        return files.find((file) => file.id === id);
      }
      return undefined;
    },
    [files]
  );

  const value = useMemo<GuardFileContextType>(
    () => ({
      files,
      filesUploading,
      deleting,
      uploading,
      refresh: load,
      deleteFile,
      handleFileUpload,
      updateFile,
      getById,
    }),
    [
      files,
      filesUploading,
      deleting,
      uploading,
      load,
      deleteFile,
      handleFileUpload,
      updateFile,
      getById,
    ]
  );

  return <GuardFileContext.Provider value={value}>{children}</GuardFileContext.Provider>;
};

export const useGuardFileContext = () => {
  const context = useContext(GuardFileContext);

  if (!context)
    throw new Error('useGuardSessionContext context must be use inside GuardSessionProvider');

  return context;
};
