import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { timer } from 'rxjs';
import { useAuthContext } from '../../../auth/useAuthContext';
import axios from '../../../utils/axios';
import { minimumDelay } from '../../../utils/MinimalDelay';
import { GuardConfigData } from '../../../data/GuardConfigData';

export interface GuardConfigContextType {
  configs: GuardConfigData[] | undefined;

  createConfig(item: Omit<GuardConfigData, 'id'>): void;

  getById(id: string): GuardConfigData | undefined;

  deleteConfig(item: GuardConfigData): void;

  refresh(): Promise<void>;
}

export const GuardConfigContext = React.createContext<GuardConfigContextType>(undefined as never);

export const GuardConfigProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();

  const [configs, setConfigs] = useState<GuardConfigData[]>();

  const updatedRef = useRef<number>();

  const load = useCallback(() => {
    const updated = updatedRef.current;
    return axios.get<GuardConfigData[]>('/guard/v1/configs').then((res) => {
      if (updatedRef.current !== updated) {
        // Discard stale data
        return;
      }

      setConfigs((prevFiles) => {
        if (JSON.stringify(prevFiles) !== JSON.stringify(res.data)) {
          return res.data.sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }

            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }

            return 0;
          });
        }
        return prevFiles;
      });
    });
  }, []);

  const fastRefresh = useMemo(() => !(Array.isArray(configs) && configs.length), [configs]);

  useEffect(() => {
    if (!isAuthenticated) {
      setConfigs(undefined);
      return undefined;
    }
    const interval = 3_000;
    const refreshTick = 30_000 / interval;
    const subscription = timer(0, interval).subscribe((tick) => {
      if (fastRefresh || (tick > 0 && tick % refreshTick === 0)) {
        return load();
      }
      return undefined;
    });
    return () => subscription.unsubscribe();
  }, [load, fastRefresh, isAuthenticated]);

  const createConfig: GuardConfigContextType['createConfig'] = useCallback(
    async (item: GuardConfigData) => {
      await minimumDelay(axios.post(`/guard/v1/configs`, item));
      await load();
    },
    [load]
  );

  const deleteConfig: GuardConfigContextType['deleteConfig'] = useCallback(
    async (config: GuardConfigData) => {
      await minimumDelay(axios.delete(`/guard/v1/configs/${config.id}`));
      await load();
    },
    [load]
  );

  const getById = useCallback<GuardConfigContextType['getById']>(
    (id) => {
      if (configs) {
        return configs.find((config) => config.id === id);
      }
      return undefined;
    },
    [configs]
  );

  const value = useMemo<GuardConfigContextType>(
    () => ({
      configs,
      createConfig,
      deleteConfig,
      getById,
      refresh: load,
    }),
    [configs, createConfig, deleteConfig, getById, load]
  );

  return <GuardConfigContext.Provider value={value}>{children}</GuardConfigContext.Provider>;
};

export const useGuardConfigContext = () => {
  const context = useContext(GuardConfigContext);

  if (!context)
    throw new Error('useGuardConfigContext context must be use inside GuardItemProvider');

  return context;
};
