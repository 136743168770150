import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
// utils
// eslint-disable-next-line import/no-extraneous-dependencies
import { timer } from 'rxjs';
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession } from './utils';

import { minimumDelay } from '../utils/MinimalDelay';
import { RefreshSessionResponse } from '../responses/RefreshSessionResponse';
import { EbError } from '../utils/EbError';
import { HttpStatus } from '../utils/HttpStatus';
import { JWTContextType } from './types';
import { AccountData, AccountPermission } from '../data/AccountData';
import { AuthenticateRequest } from '../requests/AuthenticateRequest';
import { LoginResponse } from '../responses/LoginResponse';
import { SessionState } from '../domain/SessionState';
import { IdentityRequest } from '../requests/IdentityRequest';

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [isInitialized, setInitialized] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState<AccountData>();
  const [viewRole, setViewRole] = useState<AccountPermission>();

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
        setUser(undefined);
      }
    } catch (error) {
      console.error(error);
      setAuthenticated(false);
      setUser(undefined);
    } finally {
      setInitialized(true);
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const refresh = useCallback(() => {
    axios
      .get<AccountData>(`/guard/v1/identity`)
      .then(({ data }) => {
        setInitialized(true);
        setAuthenticated(true);
        console.log(data);
        setUser({
          ...data,
          displayName: data.username,
          photoURL: data.discordUser
            ? `https://cdn.discordapp.com/avatars/${data.discordUser.discordId}/${data.discordUser.avatar}`
            : 'https://media.tenor.com/ZL-cwCOKb3MAAAAd/bert-sesame-street.gif',
        });
      })
      .catch((err) => {
        console.error('Error fetching identity', err);
      });
  }, []);

  useEffect(() => {
    if (isAuthenticated && !user) {
      refresh();
    }
  }, [refresh, isAuthenticated, user]);

  // LOGIN
  const login = useCallback(async (request: AuthenticateRequest) => {
    const { data } = await minimumDelay(
      axios.post<LoginResponse>('/guard/v1/identity/authenticate', request)
    );

    setSession(data.token);
    setAuthenticated(true);
  }, []);

  const loginWithQr = useCallback((token: string) => {
    setSession(token);
    setAuthenticated(true);
  }, []);

  // REGISTER
  const register = useCallback(async (request: IdentityRequest) => {
    const { data } = await axios.post<LoginResponse>('/guard/v1/identity/register', request);

    setSession(data.token);
    setAuthenticated(true);
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    try {
      await axios.post(`/guard/v1/identity/logout`);
    } catch (e) {
      console.error('Failed to invalidate session', e);
    }
    setViewRole(undefined);
    setSession(null);
    setAuthenticated(false);
    setUser(undefined);
  }, []);

  const changeViewRole = useCallback(
    (role: AccountPermission | undefined) => {
      if (user?.permission !== AccountPermission.SUDO) {
        return;
      }

      setViewRole(role);
    },
    [user?.permission]
  );

  const hasRole = useCallback(
    (role: AccountPermission) => (viewRole ?? user?.permission) === role,
    [user?.permission, viewRole]
  );

  useEffect(() => {
    if (!isAuthenticated) {
      return undefined;
    }
    const subscription = timer(60_000, 60_000).subscribe(() => {
      axios
        .post<RefreshSessionResponse>(`/guard/v1/identity/refresh`)
        .then(({ data }) => {
          if (data.state === SessionState.VALID) {
            //
          } else {
            console.debug(`Session has ${data.state}. Logging out...`);
            logout();
          }
        })
        .catch(async (err) => {
          const error = EbError.fromAxios(err);
          if (error.status === HttpStatus.BAD_REQUEST || error.status === HttpStatus.NOT_FOUND) {
            console.debug(`Session is no longer valid, status ${error.status}. Logging out...`);

            logout();
          } else {
            console.warn(`Failed to refresh session due to error`, error);
          }
        });
    });
    return () => subscription.unsubscribe();
  }, [logout, isAuthenticated]);

  const memoizedValue = useMemo(
    () => ({
      method: 'jwt',
      isInitialized,
      isAuthenticated,
      user,
      login,
      loginWithQr,
      register,
      refresh,
      logout,
      hasRole,
      changeViewRole,
    }),
    [
      isInitialized,
      isAuthenticated,
      user,
      login,
      loginWithQr,
      register,
      refresh,
      logout,
      hasRole,
      changeViewRole,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
