import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { timer } from 'rxjs';
import { useAuthContext } from '../../../auth/useAuthContext';
import axios from '../../../utils/axios';
import { GameServerData } from '../../../data/GameServerData';

export interface PugServerContextType {
  servers: GameServerData[] | undefined;

  getById(id: string): GameServerData | undefined;

  refresh(): Promise<void>;
}

export const PugServerContext = React.createContext<PugServerContextType>(undefined as never);

export const PugServerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();

  const [servers, setServers] = useState<GameServerData[]>();

  const updatedRef = useRef<number>();

  const load = useCallback(() => {
    const updated = updatedRef.current;
    return axios.get<GameServerData[]>('/pug/v1/servers').then((res) => {
      if (updatedRef.current !== updated) {
        // Discard stale data
        return;
      }

      setServers((prevFiles) => {
        if (JSON.stringify(prevFiles) !== JSON.stringify(res.data)) {
          return res.data.sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }

            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }

            return 0;
          });
        }
        return prevFiles;
      });
    });
  }, []);

  const fastRefresh = useMemo(() => !(Array.isArray(servers) && servers.length), [servers]);

  useEffect(() => {
    if (!isAuthenticated) {
      setServers(undefined);
      return undefined;
    }
    const interval = 3_000;
    const refreshTick = 30_000 / interval;
    const subscription = timer(0, interval).subscribe((tick) => {
      if (fastRefresh || (tick > 0 && tick % refreshTick === 0)) {
        return load();
      }
      return undefined;
    });
    return () => subscription.unsubscribe();
  }, [load, fastRefresh, isAuthenticated]);

  const getById = useCallback<PugServerContextType['getById']>(
    (id) => {
      if (servers) {
        return servers.find((server) => server.id === id);
      }
      return undefined;
    },
    [servers]
  );

  const value = useMemo<PugServerContextType>(
    () => ({
      servers,
      getById,
      refresh: load,
    }),
    [servers, getById, load]
  );

  return <PugServerContext.Provider value={value}>{children}</PugServerContext.Provider>;
};

export const usePugServerContext = () => {
  const context = useContext(PugServerContext);

  if (!context) throw new Error('usePugServerContext context must be use inside PugServerProvider');

  return context;
};
