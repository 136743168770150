import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { LicenseData } from '../../../../../data/LicenseData';
import axios from '../../../../../utils/axios';
import Scrollbar from '../../../../../components/scrollbar';
import { LogsData } from '../../../../../data/LogsData';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
  useTable,
} from '../../../../../components/table';
import { fDateTime } from '../../../../../utils/formatTime';
import { applyFilter } from '../../../../../utils/FilterUtil';

const TABLE_HEAD = [
  // {id: 'id', label: 'Id', align: 'left'},
  { id: 'createdDate', label: 'Created', align: 'left' },
  { id: 'type', label: 'Type', align: 'left' },
  { id: 'log', label: 'Log', align: 'left' },
];

export const LogsCard: React.FC<{ license: LicenseData }> = ({ license }) => {
  const [tableData, setTableData] = useState<LogsData[]>([]);

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = useTable({ defaultOrderBy: 'createdDate', defaultOrder: 'desc' });

  const load = useCallback(() => {
    axios
      .get<LogsData[]>(`/guard/v1/licenses/${license.id}/logs`)
      .then(({ data }) => {
        setTableData(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [license.id]);

  useEffect(() => {
    load();
  }, [load]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
  });

  const denseHeight = dense ? 34 : 54;

  if (tableData.length === 0) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={selected.length}
                onSort={onSort}
              />

              <TableBody>
                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow hover key={row.id} selected={selected.includes(row.id)}>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {fDateTime(row.createdDate)}
                      </TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell sx={{ wordBreak: 'break-word' }}>{row.log}</TableCell>
                    </TableRow>
                  ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={dataFiltered.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </CardContent>
    </Card>
  );
};
