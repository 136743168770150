import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Scrollbar from 'src/components/scrollbar';
import { SessionData } from '../../../../../data/SessionData';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  useTable,
} from '../../../../../components/table';
import { applyFilter } from '../../../../../utils/FilterUtil';
import axios from '../../../../../utils/axios';
import { LicenseData } from '../../../../../data/LicenseData';
import { fDateTime } from '../../../../../utils/formatTime';

const TABLE_HEAD = [
  // {id: 'id', label: 'Id', align: 'left'},
  { id: 'connectionId', label: 'Connection', align: 'left' },
  { id: 'createdDate', label: 'Created', align: 'left' },
  { id: 'lastPingDate', label: 'Last ping', align: 'left' },
  { id: 'server', label: 'Server', align: 'left' },
  { id: 'username', label: 'Username', align: 'left' },
];

export const GuardSessionCard: React.FC<{ license: LicenseData }> = ({ license }) => {
  const [tableData, setTableData] = useState<SessionData[]>([]);

  const { dense, page, order, orderBy, rowsPerPage, selected, onSort } = useTable();

  const load = useCallback(() => {
    axios
      .get<SessionData[]>(`/guard/v1/licenses/${license.id}/guard`)
      .then(({ data }) => {
        setTableData(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [license.id]);

  useEffect(() => {
    load();
  }, [load]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
  });

  const denseHeight = dense ? 34 : 54;

  if (tableData.length === 0) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={selected.length}
                onSort={onSort}
              />

              <TableBody>
                {dataFiltered
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow hover key={row.id} selected={selected.includes(row.id)}>
                      <TableCell>{row.connectionId}</TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {fDateTime(row.createdDate)}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {fDateTime(row.lastPingDate)}
                      </TableCell>
                      <TableCell>{row.server}</TableCell>
                      <TableCell>{row.username}</TableCell>
                    </TableRow>
                  ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </CardContent>
    </Card>
  );
};
