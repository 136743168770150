import axios from 'axios';
// config
import { HOST_API_KEY } from '../config-global';
import { replaceHostname } from './UrlUtils';

// ----------------------------------------------------------------------

export const DEFAULT_REQUEST_TIMEOUT_MS = 30_000;
export const DEFAULT_REQUEST_RETRY = 3;

const axiosInstance = axios.create({
  baseURL: replaceHostname(HOST_API_KEY),
  timeout: DEFAULT_REQUEST_TIMEOUT_MS,
});

/* axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
); */

export default axiosInstance;
