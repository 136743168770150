// ----------------------------------------------------------------------

import { TypographyOptions } from '@mui/material/styles/createTypography';

export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'Public Sans, sans-serif'; // Google Font
// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font

export const typography = (multiplier: number = 1): TypographyOptions => ({
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 800,
    lineHeight: (80 / 64) * multiplier,
    fontSize: pxToRem(40 * multiplier),
    ...responsiveFontSizes({ sm: 52 * multiplier, md: 58 * multiplier, lg: 64 * multiplier }),
  },
  h2: {
    fontWeight: 800,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32 * multiplier),
    ...responsiveFontSizes({ sm: 40 * multiplier, md: 44 * multiplier, lg: 48 * multiplier }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24 * multiplier),
    ...responsiveFontSizes({ sm: 26 * multiplier, md: 30 * multiplier, lg: 32 * multiplier }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20 * multiplier),
    ...responsiveFontSizes({ sm: 20 * multiplier, md: 24 * multiplier, lg: 24 * multiplier }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18 * multiplier),
    ...responsiveFontSizes({ sm: 19 * multiplier, md: 20 * multiplier, lg: 20 * multiplier }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17 * multiplier),
    ...responsiveFontSizes({ sm: 18 * multiplier, md: 18 * multiplier, lg: 18 * multiplier }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16 * multiplier),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14 * multiplier),
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16 * multiplier),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14 * multiplier),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12 * multiplier),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12 * multiplier),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14 * multiplier),
    textTransform: 'capitalize',
  },
});
