import { Navigate, useRoutes } from 'react-router-dom';
// auth
// layouts
import React from 'react';
import CompactLayout from '../layouts/compact';
// config
//
import {
  AllowedFilesPage,
  ComingSoonPage,
  ComponentsOverviewPage,
  DemoAnimatePage,
  DemoCarouselsPage,
  DemoChartsPage,
  DemoCopyToClipboardPage,
  DemoEditorPage,
  DemoFormValidationPage,
  DemoImagePage,
  DemoLabelPage,
  DemoLightboxPage,
  DemoMapPage,
  DemoMarkdownPage,
  DemoMegaMenuPage,
  DemoMultiLanguagePage,
  DemoNavigationBarPage,
  DemoOrganizationalChartPage,
  DemoScrollbarPage,
  DemoSnackbarPage,
  DemoTextMaxLinePage,
  DemoUploadPage,
  FoundationColorsPage,
  FoundationGridPage,
  FoundationIconsPage,
  FoundationShadowsPage,
  FoundationTypographyPage,
  GuardConfigPage,
  GuardDiscordAuthenticationRedirectPage,
  GuardFilePage,
  GuardItemPage,
  GuardLicensePage,
  GuardProgramPage,
  GuardScreenshotPage,
  GuardSessionPage,
  GuardSubscriptionPage,
  GuardVersionPage,
  HomePage,
  MaintenancePage,
  MfpHomePage,
  MUIAccordionPage,
  MUIAlertPage,
  MUIAutocompletePage,
  MUIAvatarPage,
  MUIBadgePage,
  MUIBreadcrumbsPage,
  MUIButtonsPage,
  MUICheckboxPage,
  MUIChipPage,
  MUIDataGridPage,
  MUIDialogPage,
  MUIListPage,
  MUIMenuPage,
  MUIPaginationPage,
  MUIPickersPage,
  MUIPopoverPage,
  MUIProgressPage,
  MUIRadioButtonsPage,
  MUIRatingPage,
  MUISliderPage,
  MUIStepperPage,
  MUISwitchPage,
  MUITablePage,
  MUITabsPage,
  MUITextFieldPage,
  MUITimelinePage,
  MUITooltipPage,
  MUITransferListPage,
  MUITreesViewPage,
  OnlinePage,
  Page403,
  Page404,
  Page500,
  PugChannelPage,
  PugConfigPage,
  PugServerPage,
  PugServerSessionPage,
  ScreenshotPage,
  VersionPage,
} from './elements';
import { AdminAuthGuard } from '../application/admin/auth/AdminAuthGuard';
import { PATH_APPLICATION } from './paths';
import DashboardLayout from '../layouts/dashboard';
import MainLayout from '../layouts/main';
import GuardLicenseDetailPage from '../pages/application/admin/guard/GuardLicenseDetailPage';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/home" replace />, index: true },
        { path: '/home', element: <HomePage /> },
        { path: 'guard/versions', element: <VersionPage /> },
        { path: 'guard/online', element: <OnlinePage /> },
        { path: 'guard/screenshots', element: <ScreenshotPage /> },
        { path: 'guard/mods', element: <AllowedFilesPage /> },
        {
          path: 'auth/discord/guard',
          element: <GuardDiscordAuthenticationRedirectPage />,
        },
      ],
    },
    {
      path: 'admin',
      element: (
        <AdminAuthGuard>
          <DashboardLayout />
        </AdminAuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_APPLICATION.admin.home} replace />, index: true },
        {
          path: 'home',
          element: <MfpHomePage />,
        },
        {
          path: 'guard',
          children: [
            { path: 'license', element: <GuardLicensePage /> },
            { path: 'license/:id', element: <GuardLicenseDetailPage /> },
            { path: 'session', element: <GuardSessionPage /> },
            { path: 'file', element: <GuardFilePage /> },
            { path: 'program', element: <GuardProgramPage /> },
            { path: 'item', element: <GuardItemPage /> },
            { path: 'subscription', element: <GuardSubscriptionPage /> },
            { path: 'screenshot', element: <GuardScreenshotPage /> },
            { path: 'version', element: <GuardVersionPage /> },
            { path: 'config', element: <GuardConfigPage /> },
          ],
        },
        {
          path: 'pug',
          children: [
            { path: 'server', element: <PugServerPage /> },
            { path: 'session', element: <PugServerSessionPage /> },
            { path: 'config', element: <PugConfigPage /> },
            { path: 'channel', element: <PugChannelPage /> },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },

    // Demo Components
    {
      path: 'components',
      children: [
        { element: <ComponentsOverviewPage />, index: true },
        {
          path: 'foundation',
          children: [
            { element: <Navigate to="/components/foundation/colors" replace />, index: true },
            { path: 'colors', element: <FoundationColorsPage /> },
            { path: 'typography', element: <FoundationTypographyPage /> },
            { path: 'shadows', element: <FoundationShadowsPage /> },
            { path: 'grid', element: <FoundationGridPage /> },
            { path: 'icons', element: <FoundationIconsPage /> },
          ],
        },
        {
          path: 'mui',
          children: [
            { element: <Navigate to="/components/mui/accordion" replace />, index: true },
            { path: 'accordion', element: <MUIAccordionPage /> },
            { path: 'alert', element: <MUIAlertPage /> },
            { path: 'autocomplete', element: <MUIAutocompletePage /> },
            { path: 'avatar', element: <MUIAvatarPage /> },
            { path: 'badge', element: <MUIBadgePage /> },
            { path: 'breadcrumbs', element: <MUIBreadcrumbsPage /> },
            { path: 'buttons', element: <MUIButtonsPage /> },
            { path: 'checkbox', element: <MUICheckboxPage /> },
            { path: 'chip', element: <MUIChipPage /> },
            { path: 'data-grid', element: <MUIDataGridPage /> },
            { path: 'dialog', element: <MUIDialogPage /> },
            { path: 'list', element: <MUIListPage /> },
            { path: 'menu', element: <MUIMenuPage /> },
            { path: 'pagination', element: <MUIPaginationPage /> },
            { path: 'pickers', element: <MUIPickersPage /> },
            { path: 'popover', element: <MUIPopoverPage /> },
            { path: 'progress', element: <MUIProgressPage /> },
            { path: 'radio-button', element: <MUIRadioButtonsPage /> },
            { path: 'rating', element: <MUIRatingPage /> },
            { path: 'slider', element: <MUISliderPage /> },
            { path: 'stepper', element: <MUIStepperPage /> },
            { path: 'switch', element: <MUISwitchPage /> },
            { path: 'table', element: <MUITablePage /> },
            { path: 'tabs', element: <MUITabsPage /> },
            { path: 'textfield', element: <MUITextFieldPage /> },
            { path: 'timeline', element: <MUITimelinePage /> },
            { path: 'tooltip', element: <MUITooltipPage /> },
            { path: 'transfer-list', element: <MUITransferListPage /> },
            { path: 'tree-view', element: <MUITreesViewPage /> },
          ],
        },
        {
          path: 'extra',
          children: [
            { element: <Navigate to="/components/extra/animate" replace />, index: true },
            { path: 'animate', element: <DemoAnimatePage /> },
            { path: 'carousel', element: <DemoCarouselsPage /> },
            { path: 'chart', element: <DemoChartsPage /> },
            { path: 'copy-to-clipboard', element: <DemoCopyToClipboardPage /> },
            { path: 'editor', element: <DemoEditorPage /> },
            { path: 'form-validation', element: <DemoFormValidationPage /> },
            { path: 'image', element: <DemoImagePage /> },
            { path: 'label', element: <DemoLabelPage /> },
            { path: 'lightbox', element: <DemoLightboxPage /> },
            { path: 'map', element: <DemoMapPage /> },
            { path: 'mega-menu', element: <DemoMegaMenuPage /> },
            { path: 'multi-language', element: <DemoMultiLanguagePage /> },
            { path: 'navigation-bar', element: <DemoNavigationBarPage /> },
            { path: 'organization-chart', element: <DemoOrganizationalChartPage /> },
            { path: 'scroll', element: <DemoScrollbarPage /> },
            { path: 'snackbar', element: <DemoSnackbarPage /> },
            { path: 'text-max-line', element: <DemoTextMaxLinePage /> },
            { path: 'upload', element: <DemoUploadPage /> },
            { path: 'markdown', element: <DemoMarkdownPage /> },
          ],
        },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
