import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Skeleton, Stack, Typography } from '@mui/material';
import { LicenseData } from '../../../../../data/LicenseData';
import { ComputerInfoData } from '../../../../../data/ComputerInfoData';
import axios from '../../../../../utils/axios';
import Scrollbar from '../../../../../components/scrollbar';

export const ComputerInfoCard: React.FC<{ license: LicenseData }> = ({ license }) => {
  const [computerInfo, setComputerInfo] = useState<ComputerInfoData>();

  const load = useCallback(() => {
    axios
      .get<ComputerInfoData>(`/guard/v1/licenses/${license.id}/computerInfo`)
      .then(({ data }) => {
        setComputerInfo(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [license.id]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Card>
      <CardHeader title="Computer info" />
      <CardContent>
        <Scrollbar sx={{ maxHeight: 400, whiteSpace: 'pre-wrap' }}>
          {!computerInfo && <Skeleton />}
          {computerInfo && (
            <Stack spacing={2}>
              <Typography sx={{ whiteSpace: 'pre' }}>
                Identifier: {computerInfo.machineKey}
              </Typography>
              <Typography>{computerInfo.systemInfo.trim()}</Typography>
            </Stack>
          )}
        </Scrollbar>
      </CardContent>
    </Card>
  );
};
