import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { timer } from 'rxjs';
import { useAuthContext } from '../../../auth/useAuthContext';
import axios from '../../../utils/axios';
import { LicenseData } from '../../../data/LicenseData';

export interface GuardLicenseContextType {
  licenses: LicenseData[] | undefined;

  getById(id?: string): LicenseData | undefined;

  refresh(): Promise<void>;
}

export const GuardLicenseContext = React.createContext<GuardLicenseContextType>(undefined as never);

export const GuardLicenseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();

  const [licenses, setLicenses] = useState<LicenseData[]>();

  const updatedRef = useRef<number>();

  const load = useCallback(() => {
    const updated = updatedRef.current;
    return axios.get<LicenseData[]>('/guard/v1/licenses').then((res) => {
      if (updatedRef.current !== updated) {
        // Discard stale data
        return;
      }

      setLicenses((prevFiles) => {
        if (JSON.stringify(prevFiles) !== JSON.stringify(res.data)) {
          return res.data;
        }
        return prevFiles;
      });
    });
  }, []);

  const fastRefresh = useMemo(() => !(Array.isArray(licenses) && licenses.length), [licenses]);

  useEffect(() => {
    if (!isAuthenticated) {
      setLicenses(undefined);
      return undefined;
    }

    const interval = 3_000;
    const refreshTick = 30_000 / interval;
    const subscription = timer(0, interval).subscribe((tick) => {
      if (fastRefresh || (tick > 0 && tick % refreshTick === 0)) {
        return load();
      }

      return undefined;
    });
    return () => subscription.unsubscribe();
  }, [load, fastRefresh, isAuthenticated]);

  const getById = useCallback<GuardLicenseContextType['getById']>(
    (id) => {
      if (licenses) {
        return licenses.find((license) => license.id === id);
      }
      return undefined;
    },
    [licenses]
  );

  const value = useMemo<GuardLicenseContextType>(
    () => ({
      licenses,
      getById,
      refresh: load,
    }),
    [licenses, getById, load]
  );

  return <GuardLicenseContext.Provider value={value}>{children}</GuardLicenseContext.Provider>;
};

export const useGuardLicenseContext = () => {
  const context = useContext(GuardLicenseContext);

  if (!context)
    throw new Error('useGuardLicenseContext context must be use inside GuardLicenseProvider');

  return context;
};
