import React, { Suspense, useCallback, useContext, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

export type ApplicationContextType = {
  confirmDelete(confirmed: () => Promise<any>): void;
};

export const ApplicationContext = React.createContext<ApplicationContextType>(undefined as never);

export const ApplicationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [pendingConfirmDelete, setPendingConfirmDelete] = useState<
    {
      resolve(confirmed: boolean): void;
      confirmed(): Promise<any>;
    }[]
  >([]);

  const confirmDel = pendingConfirmDelete && pendingConfirmDelete[0];

  const handleClose = useCallback(() => {
    setPendingConfirmDelete((prevState) => {
      const updatedConfirmDelete = [...prevState];
      updatedConfirmDelete.splice(updatedConfirmDelete.indexOf(confirmDel), 1);
      return updatedConfirmDelete;
    });
    confirmDel.resolve(true);
  }, [confirmDel]);

  const confirmDelete = (confirmed: () => Promise<any>): Promise<boolean> =>
    new Promise((resolve) => {
      setPendingConfirmDelete((prevState) => [
        {
          resolve,
          confirmed,
        },
        ...prevState,
      ]);
    });

  const value = useMemo<ApplicationContextType>(
    () => ({
      confirmDelete,
    }),
    []
  );

  return (
    <ApplicationContext.Provider value={value}>
      {children}
      <Suspense fallback={null}>
        <Dialog
          open={!!confirmDel}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant="contained"
              color="error"
              loading={isSubmitting}
              onClick={async () => {
                if (confirmDel) {
                  setSubmitting(true);
                  try {
                    await confirmDel.confirmed();
                    await handleClose();
                  } finally {
                    setSubmitting(false);
                  }
                }
              }}
            >
              Delete
            </LoadingButton>
            <Button variant="outlined" color="inherit" onClick={handleClose} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Suspense>
    </ApplicationContext.Provider>
  );
};

export const useApplicationContext = () => {
  const context = useContext(ApplicationContext);

  if (!context) throw new Error('useApplicationContext must be used inside ApplicationProvider');

  return context;
};
