import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { timer } from 'rxjs';
import { useAuthContext } from '../../../auth/useAuthContext';
import axios from '../../../utils/axios';
import { PugConfigData } from '../../../data/PugConfigData';
import { minimumDelay } from '../../../utils/MinimalDelay';

export interface PugConfigContextType {
  configs: PugConfigData[] | undefined;

  createConfig(item: Omit<PugConfigData, 'id'>): void;

  deleteConfig(item: PugConfigData): void;

  getById(id: string): PugConfigData | undefined;

  refresh(): Promise<void>;
}

export const PugConfigContext = React.createContext<PugConfigContextType>(undefined as never);

export const PugConfigProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();

  const [configs, setConfigs] = useState<PugConfigData[]>();

  const updatedRef = useRef<number>();

  const load = useCallback(() => {
    const updated = updatedRef.current;
    return axios.get<PugConfigData[]>('/pug/v1/configs').then((res) => {
      if (updatedRef.current !== updated) {
        // Discard stale data
        return;
      }

      setConfigs((prevFiles) => {
        if (JSON.stringify(prevFiles) !== JSON.stringify(res.data)) {
          return res.data.sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }

            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }

            return 0;
          });
        }
        return prevFiles;
      });
    });
  }, []);

  const fastRefresh = useMemo(() => !(Array.isArray(configs) && configs.length), [configs]);

  useEffect(() => {
    if (!isAuthenticated) {
      setConfigs(undefined);
      return undefined;
    }
    const interval = 3_000;
    const refreshTick = 30_000 / interval;
    const subscription = timer(0, interval).subscribe((tick) => {
      if (fastRefresh || (tick > 0 && tick % refreshTick === 0)) {
        return load();
      }
      return undefined;
    });
    return () => subscription.unsubscribe();
  }, [load, fastRefresh, isAuthenticated]);

  const createConfig: PugConfigContextType['createConfig'] = useCallback(
    async (item: PugConfigData) => {
      await minimumDelay(axios.post(`/pug/v1/configs`, item));
      await load();
    },
    [load]
  );

  const deleteConfig: PugConfigContextType['createConfig'] = useCallback(
    async (item: PugConfigData) => {
      await minimumDelay(axios.delete(`/pug/v1/configs/${item.id}`));
      await load();
    },
    [load]
  );

  const getById = useCallback<PugConfigContextType['getById']>(
    (id) => {
      if (configs) {
        return configs.find((cfg) => cfg.id === id);
      }
      return undefined;
    },
    [configs]
  );

  const value = useMemo<PugConfigContextType>(
    () => ({
      configs,
      createConfig,
      deleteConfig,
      getById,
      refresh: load,
    }),
    [configs, createConfig, deleteConfig, getById, load]
  );

  return <PugConfigContext.Provider value={value}>{children}</PugConfigContext.Provider>;
};

export const usePugConfigContext = () => {
  const context = useContext(PugConfigContext);

  if (!context) throw new Error('usePugConfigContext context must be use inside PugConfigProvider');

  return context;
};
