import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '../../../components/loading-screen';
//
import { useAuthContext } from '../../../auth/useAuthContext';
import { GuardLicenseProvider } from '../guard/LicenseContext';
import { GuardSessionProvider } from '../guard/GuardSessionContext';
import { GuardFileProvider } from '../guard/GuardFileContext';
import { GuardProgramProvider } from '../guard/GuardProgramContext';
import { GuardItemProvider } from '../guard/GuardItemContext';
import { GuardSubscriptionProvider } from '../guard/GuardSubscriptionContext';
import { GuardScreenshotProvider } from '../guard/GuardScreenshotContext';
import { PugServerProvider } from '../guard/PugServerContext';
import { PugServerSessionProvider } from '../guard/PugServerSessionContext';
import { AdminLoginPage } from '../../../routes/elements';
import { GuardVersionProvider } from '../guard/GuardVersionContext';
import { PugConfigProvider } from '../pug/PugConfigContext';
import { PugChannelProvider } from '../pug/PugChannelContext';
import { GuardConfigProvider } from '../guard/GuardConfigContext';

// ----------------------------------------------------------------------

interface AdminAuthGuardProps {
  children: React.ReactNode;
}

export const AdminAuthGuard: React.FC<AdminAuthGuardProps> = ({ children }) => {
  const { isAuthenticated, isInitialized, user } = useAuthContext();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <AdminLoginPage />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (!user) {
    return <LoadingScreen />;
  }

  return (
    <GuardLicenseProvider>
      <GuardSessionProvider>
        <GuardVersionProvider>
          <GuardFileProvider>
            <GuardProgramProvider>
              <GuardItemProvider>
                <GuardSubscriptionProvider>
                  <GuardScreenshotProvider>
                    <GuardConfigProvider>
                      <PugServerProvider>
                        <PugServerSessionProvider>
                          <PugConfigProvider>
                            <PugChannelProvider>{children}</PugChannelProvider>
                          </PugConfigProvider>
                        </PugServerSessionProvider>
                      </PugServerProvider>
                    </GuardConfigProvider>
                  </GuardScreenshotProvider>
                </GuardSubscriptionProvider>
              </GuardItemProvider>
            </GuardProgramProvider>
          </GuardFileProvider>
        </GuardVersionProvider>
      </GuardSessionProvider>
    </GuardLicenseProvider>
  );
};
