import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { timer } from 'rxjs';
import { useSnackbar } from 'notistack';
import { useAuthContext } from 'src/auth/useAuthContext';
import axios from '../../../utils/axios';
import { minimumDelay } from '../../../utils/MinimalDelay';
import { GuardVersionData, GuardVersionUploadingData } from '../../../data/GuardVersionData';

export interface GuardVersionContextType {
  versions: GuardVersionData[] | undefined;
  versionsUploading: GuardVersionUploadingData[];

  deleting: string[];
  uploading: boolean;

  getById(id: string): GuardVersionData | undefined;

  deleteVersion(version: GuardVersionData): void;

  handleVersionUpload(
    eFile: File,
    version: string,
    key: string,
    iv: string,
    description: string
  ): void;

  refresh(): Promise<void>;

  updateVersion(id: string, data: Partial<GuardVersionData>): void;
}

export const GuardVersionContext = React.createContext<GuardVersionContextType>(undefined as never);

export const GuardVersionProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [versions, setVersions] = useState<GuardVersionData[]>();
  const [deleting, setDeleting] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false);
  const [versionsUploading, setVersionsUploading] = useState<GuardVersionUploadingData[]>([]);

  const updatedRef = useRef<number>();

  const updateVersion: GuardVersionContextType['updateVersion'] = useCallback((id, data) => {
    setVersions((prevFiles) => {
      updatedRef.current = Date.now();
      if (Array.isArray(prevFiles)) {
        const index = prevFiles.findIndex((prevFile) => prevFile.id === id);
        if (index >= 0) {
          const newFiles = [...prevFiles];
          newFiles[index] = {
            ...prevFiles[index],
            ...data,
          };
          return newFiles;
        }
      }
      return prevFiles;
    });
  }, []);

  const load = useCallback(() => {
    const updated = updatedRef.current;
    return axios.get<GuardVersionData[]>('/guard/v1/versions').then((res) => {
      if (updatedRef.current !== updated) {
        // Discard stale data
        return;
      }

      setVersions((prevFiles) => {
        if (JSON.stringify(prevFiles) !== JSON.stringify(res.data)) {
          return res.data;
        }
        return prevFiles;
      });
    });
  }, []);

  const fastRefresh = useMemo(() => !(Array.isArray(versions) && versions.length), [versions]);

  useEffect(() => {
    if (isAuthenticated) {
      const interval = 3_000;
      const refreshTick = 30_000 / interval;
      const subscription = timer(0, interval).subscribe((tick) => {
        if (fastRefresh || (tick > 0 && tick % refreshTick === 0)) {
          return load();
        }

        return undefined;
      });
      return () => subscription.unsubscribe();
    }

    setVersions(undefined);

    return undefined;
  }, [load, fastRefresh, isAuthenticated]);

  const deleteVersion: GuardVersionContextType['deleteVersion'] = useCallback(
    async (file: GuardVersionData) => {
      setDeleting((prevDeleting) => [file.id, ...prevDeleting]);
      await minimumDelay(axios.delete(`/guard/v1/versions/${file.id}`));
      setVersions((prevFiles) => {
        if (Array.isArray(prevFiles)) {
          return prevFiles.filter((prevFile) => prevFile.id !== file.id);
        }
        return prevFiles;
      });
      setDeleting((prevDeleting) => prevDeleting.filter((id) => id !== file.id));
    },
    []
  );

  /**
   * @param progress 0-100 for progress, when negative the file is removed
   */
  const setUploadProgress = useCallback((id: string, progress: number) => {
    setVersionsUploading((prev) => {
      const _filesUploading = [...prev];
      const index = _filesUploading.findIndex((file) => file.id === id);
      if (index >= 0) {
        if (progress < 0) {
          _filesUploading.splice(index, 1);
        } else {
          _filesUploading[index] = {
            ..._filesUploading[index],
            progress,
          };
        }
      }
      return _filesUploading;
    });
  }, []);

  const handleVersionUpload = useCallback(
    (eFile: File, version: string, key: string, iv: string, description: string) => {
      setUploading(true);

      const tempId = crypto.randomUUID();
      const formData = new FormData();
      console.log(eFile.name);
      formData.append('file', eFile, eFile.name);
      setVersionsUploading((prev) => [
        ...prev,
        {
          id: tempId,
          version: eFile.name,
          progress: 0,
        },
      ]);
      return axios
        .post<GuardVersionData[]>(`/guard/v1/versions`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          params: {
            version,
            key,
            iv,
            description,
          },
          timeout: 300_000,
        })
        .then((res) => {
          setVersions((prevFiles) => {
            if (Array.isArray(prevFiles)) {
              const newFiles = [...prevFiles];
              const newFile = res.data[0];
              if (newFiles.every((pf) => newFile.id !== pf.id)) {
                // Only insert the newFile if it isn't already listed (this can occur due to polling)
                newFiles.push(newFile);
              }
              newFiles.sort(
                (a, b) => new Date(b.released).getTime() - new Date(a.released).getTime()
              );
              return newFiles;
            }
            return res.data;
          });
        })
        .catch((err) => {
          enqueueSnackbar('Er ging iets fout bij het uploaden', { variant: 'error' });
        })
        .finally(() => {
          setUploadProgress(tempId, -1);
          setUploading(false);
        });
    },
    [enqueueSnackbar, setUploadProgress]
  );

  const getById = useCallback<GuardVersionContextType['getById']>(
    (id) => {
      if (versions) {
        return versions.find((file) => file.id === id);
      }
      return undefined;
    },
    [versions]
  );

  const value = useMemo<GuardVersionContextType>(
    () => ({
      versions,
      versionsUploading,
      deleting,
      uploading,
      refresh: load,
      deleteVersion,
      handleVersionUpload,
      updateVersion,
      getById,
    }),
    [
      versions,
      versionsUploading,
      deleting,
      uploading,
      load,
      deleteVersion,
      handleVersionUpload,
      updateVersion,
      getById,
    ]
  );

  return <GuardVersionContext.Provider value={value}>{children}</GuardVersionContext.Provider>;
};

export const useGuardVersionContext = () => {
  const context = useContext(GuardVersionContext);

  if (!context)
    throw new Error('useGuardVersionContext context must be use inside GuardVersionProvider');

  return context;
};
