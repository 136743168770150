// routes
// config
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/home',
  },
  {
    title: 'Guard',
    path: '/guard',
    icon: <Iconify icon="ic:round-grain" />,
    children: [
      {
        subheader: '',
        items: [
          { title: 'Versions', path: '/guard/versions' },
          { title: 'Online', path: '/guard/online' },
          { title: 'Screenshots', path: '/guard/screenshots' },
          { title: 'Allowed files', path: '/guard/mods' },
        ],
      },
    ],
  },
];

export default navConfig;
