import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { timer } from 'rxjs';
import { ScreenshotData } from '../../../data/ScreenshotData';
import { useAuthContext } from '../../../auth/useAuthContext';
import axios from '../../../utils/axios';

export interface GuardScreenshotContextType {
  screenshots: ScreenshotData[] | undefined;

  getById(id: string): ScreenshotData | undefined;

  refresh(): Promise<void>;
}

export const GuardScreenshotContext = React.createContext<GuardScreenshotContextType>(
  undefined as never
);

export const GuardScreenshotProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();

  const [screenshots, setScreenshots] = useState<ScreenshotData[]>();

  const updatedRef = useRef<number>();

  const load = useCallback(() => {
    const updated = updatedRef.current;
    return axios.get<ScreenshotData[]>('/guard/v1/screenshots').then((res) => {
      if (updatedRef.current !== updated) {
        // Discard stale data
        return;
      }

      setScreenshots((prevFiles) => {
        if (JSON.stringify(prevFiles) !== JSON.stringify(res.data)) {
          return res.data;
        }
        return prevFiles;
      });
    });
  }, []);

  const fastRefresh = useMemo(
    () => !(Array.isArray(screenshots) && screenshots.length),
    [screenshots]
  );

  useEffect(() => {
    if (!isAuthenticated) {
      setScreenshots(undefined);
      return undefined;
    }
    const interval = 3_000;
    const refreshTick = 30_000 / interval;
    const subscription = timer(0, interval).subscribe((tick) => {
      if (fastRefresh || (tick > 0 && tick % refreshTick === 0)) {
        return load();
      }
      return undefined;
    });
    return () => subscription.unsubscribe();
  }, [load, fastRefresh, isAuthenticated]);

  const getById = useCallback<GuardScreenshotContextType['getById']>(
    (id) => {
      if (screenshots) {
        return screenshots.find((license) => license.id === id);
      }
      return undefined;
    },
    [screenshots]
  );

  const value = useMemo<GuardScreenshotContextType>(
    () => ({
      screenshots,
      getById,
      refresh: load,
    }),
    [screenshots, getById, load]
  );

  return (
    <GuardScreenshotContext.Provider value={value}>{children}</GuardScreenshotContext.Provider>
  );
};

export const useGuardScreenshotContext = () => {
  const context = useContext(GuardScreenshotContext);

  if (!context)
    throw new Error(
      'useGuardSubscriptionContext context must be use inside GuardScreenshotProvider'
    );

  return context;
};
