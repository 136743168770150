/**
 * Wraps a promise and ensures the promise does not resolve before the minDelay has passed.
 * The use case is to deliberately delay actions, so the user feels the app is doing something (e.g. show loading state).
 * @param promise
 * @param minDelay
 */
export const minimumDelay = <T>(promise: Promise<T>, minDelay: number = 500): Promise<T> => {
  const start = Date.now();
  return new Promise((resolve, reject) => {
    promise
      .then((result) => {
        const passed = Date.now() - start;
        if (passed >= minDelay) {
          resolve(result);
        } else {
          setTimeout(() => resolve(result), minDelay - passed);
        }
      })
      .catch((err) => {
        const passed = Date.now() - start;
        if (passed >= minDelay) {
          reject(err);
        } else {
          setTimeout(() => reject(err), minDelay - passed);
        }
      });
  });
};
