export interface AccountData {
  id: string;
  username: string;
  discordUser?: {
    discordId: string;
    username: string;
    avatar: string;
  };
  permission: AccountPermission;

  [k: string]: any;
}

export enum AccountPermission {
  SUDO = 'SUDO',
  ADMIN = 'ADMIN',
  SLAVE = 'SLAVE',
}
