import { Helmet } from 'react-helmet-async';
import { Card, CardContent, CardHeader, Container, Grid } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router';
import { Navigate } from 'react-router-dom';
import { useSettingsContext } from '../../../../components/settings';
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
import { PATH_APPLICATION, PATH_PAGE } from '../../../../routes/paths';
import RoleBasedGuard from '../../../../auth/RoleBasedGuard';
import { AccountPermission } from '../../../../data/AccountData';
import { useGuardLicenseContext } from '../../../../application/admin/guard/LicenseContext';
import { CustomAvatar } from '../../../../components/custom-avatar';
import { fDateTime } from '../../../../utils/formatTime';
import { ComputerInfoCard } from '../../../../sections/@application/admin/guard/license/ComputerInfoCard';
import LoadingScreen from '../../../../components/loading-screen';
import { Row } from '../../../../utils/Row';
import { GuardSessionCard } from '../../../../sections/@application/admin/guard/license/GuardSessionCard';
import { LogsCard } from '../../../../sections/@application/admin/guard/license/LogsCard';

export default function GuardLicenseDetailPage() {
  const { themeStretch } = useSettingsContext();
  const { id } = useParams();

  const { getById, licenses } = useGuardLicenseContext();
  const license = getById(id);

  if (!licenses) {
    return <LoadingScreen />;
  }

  if (!license) {
    return <Navigate to={PATH_PAGE.page404} />;
  }

  return (
    <RoleBasedGuard hasContent roles={[AccountPermission.SUDO, AccountPermission.ADMIN]}>
      <Helmet>
        <title> The starting point for your next project | Minimal UI</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading="Guard licenses"
          links={[
            {
              name: 'home',
              href: PATH_APPLICATION.admin.home,
            },
            {
              name: 'Guard',
            },
            {
              name: 'Licenses',
              href: PATH_APPLICATION.admin.guard.license,
            },
            {
              name: license.id,
            },
          ]}
        />

        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <GuardSessionCard license={license} />
          </Grid>
          <Grid item xs={6} md={4}>
            <Card>
              <CardHeader title="User information" />
              <CardContent>
                <Row label="Username" value={license.username} />
                {license.systemHash && <Row label="System" value={license.systemHash} />}
                <Row label="Guid" value={license.guid} />
                <Row label="Registered" value={fDateTime(license.registrationDate)} />
                <Row label="Last startup" value={fDateTime(license.lastStartupDate)} />
                <Row label="Last ping" value={fDateTime(license.lastPingDate)} />
                <Row label="Version" value={license.version?.version || 'N/A'} />
                <Row label="Target version" value={license.targetVersion?.version || 'N/A'} />
                <Row label="Role" value={license.permission} />
              </CardContent>
            </Card>
          </Grid>
          {license.discordUser && (
            <Grid item xs={6} md={4}>
              <Card>
                <CardHeader
                  title="Discord information"
                  action={
                    <CustomAvatar
                      src={`https://cdn.discordapp.com/avatars/${license.discordUser.discordId}/${license.discordUser.avatar}`}
                      alt={license.discordUser.username}
                      name={license.discordUser.username}
                    />
                  }
                />
                <CardContent>
                  <Row label="Id" value={license.discordUser.discordId} />
                  <Row label="Username" value={license.discordUser.username} />
                </CardContent>
              </Card>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <ComputerInfoCard license={license} />
          </Grid>
          <Grid item xs={12} md={6}>
            <LogsCard license={license} />
          </Grid>
        </Grid>
      </Container>
    </RoleBasedGuard>
  );
}
