// @mui
import { Box, Typography } from '@mui/material';
// routes
// _mock
// components

// ----------------------------------------------------------------------

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        px: 2,
        py: 2,
        textAlign: 'left',
        // position: 'absolute',
        // bottom: 0,
      }}
    >
      <Typography variant="caption" component="div">
        ©{new Date().getFullYear()} SoF2Global - All rights reserved
      </Typography>
    </Box>
  );
}
