import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { timer } from 'rxjs';
import { useAuthContext } from '../../../auth/useAuthContext';
import axios from '../../../utils/axios';
import { SubscriptionData } from '../../../data/SubscriptionData';
import { minimumDelay } from '../../../utils/MinimalDelay';

export interface GuardSubscriptionContextType {
  subscriptions: SubscriptionData[] | undefined;

  createSubscription(
    subscription: Omit<SubscriptionData, 'id' | 'createdDate' | 'lastUpdatedDate' | 'endDate'>
  ): void;

  deleteSubscription(subscription: SubscriptionData): void;

  getById(id: string): SubscriptionData | undefined;

  refresh(): Promise<void>;
}

export const GuardSubscriptionContext = React.createContext<GuardSubscriptionContextType>(
  undefined as never
);

export const GuardSubscriptionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated } = useAuthContext();

  const [subscriptions, setSubscriptions] = useState<SubscriptionData[]>();

  const updatedRef = useRef<number>();

  const load = useCallback(() => {
    const updated = updatedRef.current;
    return axios.get<SubscriptionData[]>('/guard/v1/subscriptions').then((res) => {
      if (updatedRef.current !== updated) {
        // Discard stale data
        return;
      }

      setSubscriptions((prevFiles) => {
        if (JSON.stringify(prevFiles) !== JSON.stringify(res.data)) {
          return res.data;
        }
        return prevFiles;
      });
    });
  }, []);

  const fastRefresh = useMemo(
    () => !(Array.isArray(subscriptions) && subscriptions.length),
    [subscriptions]
  );

  useEffect(() => {
    if (!isAuthenticated) {
      setSubscriptions(undefined);
      return undefined;
    }
    const interval = 3_000;
    const refreshTick = 30_000 / interval;
    const subscription = timer(0, interval).subscribe((tick) => {
      if (fastRefresh || (tick > 0 && tick % refreshTick === 0)) {
        return load();
      }
      return undefined;
    });
    return () => subscription.unsubscribe();
  }, [load, fastRefresh, isAuthenticated]);

  const createSubscription: GuardSubscriptionContextType['createSubscription'] = useCallback(
    async (subscription: SubscriptionData) => {
      await minimumDelay(axios.post(`/guard/v1/subscriptions`, subscription));
      await load();
    },
    [load]
  );

  const deleteSubscription: GuardSubscriptionContextType['deleteSubscription'] = useCallback(
    async (subscription: SubscriptionData) => {
      await minimumDelay(axios.delete(`/guard/v1/subscriptions/${subscription.id}`));
      await load();
    },
    [load]
  );

  const getById = useCallback<GuardSubscriptionContextType['getById']>(
    (id) => {
      if (subscriptions) {
        return subscriptions.find((subscription) => subscription.id === id);
      }
      return undefined;
    },
    [subscriptions]
  );

  const value = useMemo<GuardSubscriptionContextType>(
    () => ({
      subscriptions,
      createSubscription,
      deleteSubscription,
      getById,
      refresh: load,
    }),
    [subscriptions, createSubscription, deleteSubscription, getById, load]
  );

  return (
    <GuardSubscriptionContext.Provider value={value}>{children}</GuardSubscriptionContext.Provider>
  );
};

export const useGuardSubscriptionContext = () => {
  const context = useContext(GuardSubscriptionContext);

  if (!context)
    throw new Error(
      'useGuardSubscriptionContext context must be use inside GuardSubscriptionProvider'
    );

  return context;
};
